import React from "react"
import { Link } from "gatsby"
import Icon from "../../images/bilisim-avukati.svg"

const Sections = () => {
  return (
    <div>
      <div id="intro" className="section services">
        <div className="wrap-wide">
          <h3>
            Bilişim Hukukçularımız size hangi konularda yardımcı olabilir?
          </h3>
          <h2>
            Mükemmel bilişim avukatları ekibimiz, müşterilerin ürünlerini
            korumalarına, sözleşmelerini müzakere etmelerine ve haklarını
            savunmalarına yardımcı olma konusunda uzmanlaşmıştır.
          </h2>
        </div>
        <div className="wrap-wide">
          <Link to="/calisma-alanlari/sosyal-medya-avukati" className="service">
            <div className="icon-service">
              <Icon />
            </div>
            <h4>Sosyal Medya Avukatı</h4>
            <p>
              Sosyal Medya İçeren Hukuki Sorunları Yönlendiren Avukatlar ve
              Tüketicilerle Etkileşimde Bulunan En Son Teknoloji.
            </p>
          </Link>
          <Link to="/calisma-alanlari/fenomen-avukati" className="service">
            <div className="icon-service">
              <Icon />
            </div>
            <h4>Fenomen Avukatı</h4>
            <p>
              Fenomen Avukatları. Fenomenlere uygun maliyetli bir oranda akıllı
              sosyal medya yönetimi ve koruma hizmetleri sunuyoruz.
            </p>
          </Link>
          <Link to="/calisma-alanlari/bilisim-avukati" className="service">
            <div className="icon-service">
              <Icon />
            </div>
            <h4>Bilişim Avukatı</h4>
            <p>
              Bilişim avukatı: Patent ve Korsanlık, Bilgi Hırsızlığı, Hakaret
              gibi Teknoloji ile ilgili konularda tavsiye verebilecek kişidir.
            </p>
          </Link>
        </div>
      </div>
      <div className="stripe white-nobg" />
    </div>
  )
}

export default Sections
