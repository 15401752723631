import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Steps = () => {
  return (
    <div className="section steps">
      <div className="wrap">
        <h2>Uzman avukat kadromuz müvekkillerine ne sağlamaktadır?</h2>
        <div className="steps-item">
          <div className="steps-visual">
            <div className="steps-shape">
              <StaticImage
                className="img"
                src="../../images/guven.png"
                alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <h4>Güven</h4>
          <p>
            Bilişim avukatı olarak sıkıntılı noktaların üstesinden gelmek için
            bir strateji oluşturuyoruz.
          </p>
        </div>
        <div className="steps-item">
          <div className="steps-visual">
            <div className="steps-shape">
              <StaticImage
                className="img"
                src="../../images/tecrube.png"
                alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <h4>Deneyim</h4>
          <p>
            Bilişim ekibimizle birlikte çalışıyoruz. İhtiyaçlarınıza özel çözüm
            sunuyoruz.
          </p>
        </div>
        <div className="steps-item">
          <div className="steps-visual">
            <div className="steps-shape">
              <StaticImage
                className="img"
                src="../../images/deneyim.png"
                alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <h4>Kazanç</h4>
          <p>
            Stratejik rehberlik yoluyla destek veriyoruz ve sürekli destek
            sağlıyoruz.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Steps
